@import "../../common/mixins.scss";

.knowledge-score-madal {
    .modal-body {
        padding: 0;
    }
}

.Content {
    width: pxtorem(520);
    height: pxtorem(44);
    font-size: $remof14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.57;
    letter-spacing: normal;
    color: #252525;
    margin-bottom: $remof20px;
}

.container {
    width: pxtorem(663);
    height: pxtorem(444);
    border-radius: $remof2px;
    background-color: #ffffff;
    border: solid $remof1px #d9d9d9;
}

.container1 {
    padding-top: $remof25px;
    padding-left: $remof25px;
}

.header {
    width: pxtorem(520);
    height: pxtorem(28);
    font-size: $remof24px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.17;
    letter-spacing: normal;
    color: #252525;
    margin-bottom: $remof20px;
}

.high-swarm {
    width: pxtorem(8);
    height: pxtorem(8);
    border-radius: 50%;
    display: inline-block;
}

.bullet-achiever {
    margin-right: $remof14px;
    background-color: #038238;
}

.bullet-at-expectation {
    margin-right: $remof14px;
    background-color: #ffb81c;
}

.bullet-strugglers {
    margin-right: $remof14px;
    background-color: #ca4644;
}

.Slider {
    width: 100%;
    height: $remof25px;
    box-sizing: border-box;
}

.SliderRange {
    float: right;
    margin: pxtorem(0) pxtorem(0) pxtorem(0) pxtorem(0);
    box-sizing: border-box;
    padding: 0;
}

.EndLine {
    width: 97.5%;
    height: $remof1px;
    border: $remof1px solid #d9d9d9;
    margin: $remof18px pxtorem(0) $remof18px pxtorem(0);
}

.SliderText {
    width: pxtorem(130);
    font-size: pxtorem(14);
}

.error-message {
    font-size: pxtorem(14);
    color: red;
}

.slider-number-field {
    text-align: center;
    width: pxtorem(52);
    height: pxtorem(36);
    border-radius: pxtorem(3);
    background-color: #ffffff;
    border: solid 1px #c7c7c7;
    font-size: $remof14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.29;
    letter-spacing: normal;
    color: #252525;
}

.slider-number-field:disabled {
    color: #6a7070;
    background-color: #f3f3f3;
}

.linkbutton {
    background: none !important;
    border: none;
    padding: 0 !important;
    border-bottom: pxtorem(0) solid #444;
    cursor: pointer;
    margin-top: $remof5px;
    width: pxtorem(112);
    height: pxtorem(19);
    font-size: $remof14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #047a9c;
}

.button {
    background: none;
    width: pxtorem(75);
    height: pxtorem(35);
    text-align: center;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-border-radius: pxtorem(3);
    -moz-border-radius: pxtorem(3);
    padding: pxtorem(7);
    outline: none;
    border-radius: $remof2px;
    background-color: #e9e9e9;
    border: solid $remof1px #c7c7c7;
}

.cancelbutton {
    width: pxtorem(128);
    height: pxtorem(32);
    font-size: $remof14px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.29;
    letter-spacing: normal;
    text-align: center;
    color: #6a7070;
    border-radius: pxtorem(30);
    background: rgba(217, 217, 217, 0.6);
    border: none;
}

.savebutton {
    width: pxtorem(128);
    height: pxtorem(32);
    font-size: $remof14px;
    background-color: #047a9c;
    margin-left: $remof20px;
    color: #ffffff;
    border-radius: pxtorem(30);
}

.buttonGroup {
    margin-top: pxtorem(52);
}

.text-box-col {
    padding-left: 0;
}

@media (min-width: pxtorem(1200)) {
    .pull-right-lg {
        float: right;
    }
}

@media (min-width: pxtorem(992)) {
    .pull-right-md {
        float: right;
    }
}

@media (min-width: pxtorem(768)) {
    .pull-right-sm {
        float: right;
    }
}

@media (max-width: pxtorem(768)) {
    .container {
        width: auto;
        height: auto;
    }
    .Content {
        width: auto;
        height: auto;
    }
    .savebutton {
        margin-left: pxtorem(0);
    }
}

@media (max-width: pxtorem(992)) {
    .pull-right-md {
        float: left;
    }
}

@media (max-width: pxtorem(1200)) {
    .pull-right-lg {
        float: left;
    }
}
