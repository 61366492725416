@import "./common/fonts.scss";
@import "./common/mixins.scss";

html {
    font-size: 16px;
}

body {
    font-family: "Open Sans","Calibri", "Tahoma","sans-serif";
    margin: 0;
    overflow: hidden;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -ms-overflow-style: scrollbar;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

* {
    box-sizing: border-box;
}

.noselect {
    -webkit-touch-callout: none; // iOS Safari
    -webkit-user-select: none; // Safari
    -khtml-user-select: none; // Konqueror HTML
    -moz-user-select: none; // Old versions of Firefox
    -ms-user-select: none; // Internet Explorer/Edge
    user-select: none; // Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox
}

.fontWeightBold {
    font-weight: 600;
}

.cursor-pointer {
    cursor: pointer;
}

.alert-pii-error {
    border-color: #f9bf06;
    border-style: solid;
    border-width: $remof1px $remof1px $remof1px $remof5px;
    color: #664d03;
    font-size: $remof14px;
    line-height: $remof18px;
    padding: $remof5px $remof25px;
}

/************* Scrollbar Start ***************/
::-webkit-scrollbar {
    height: pxtorem(8);
    width: $remof5px;
    background-color: #fff;
    border-radius: $remof80px;
    -webkit-border-radius: $remof80px;
}

::-webkit-scrollbar:hover {
    background-color: #fff;
}

::-webkit-scrollbar-thumb:vertical {
    background: #dfdfdf;
    border-radius: $remof80px;
    -webkit-border-radius: $remof80px;
}

::-webkit-scrollbar-thumb:vertical:active {
    background: #dfdfdf;
    border-radius: $remof80px;
    -webkit-border-radius: $remof80px;
}

::-webkit-scrollbar-thumb:horizontal {
    background: #dfdfdf;
    border-radius: $remof80px;
    -webkit-border-radius: $remof80px;
}

::-webkit-scrollbar-thumb:horizontal:active {
    background: #dfdfdf;
    border-radius: $remof80px;
    -webkit-border-radius: $remof80px;
}

/************* Scrollbar End ***************/
