@import "../../common/mixins.scss";

.student-details {
    &-container {
        @import '../topics/topics-list.scss';

        .highlight-text {
            text-decoration: underline;
        }

        .emailContainer {
            overflow-y: hidden;

            .modal-dialog {
                vertical-align: top !important;
                top: pxtorem(30);
            }
        }
        .ca-nocourse-error {
            margin-top: 15px;
        }
    }

    &-content {
        padding: pxtorem(30) pxtorem(30) pxtorem(12);
    }

    &-actions {
        align-items: center;
        border-bottom: $remof1px solid #e9e9e9;
        display: flex;
        height: pxtorem(48);
        justify-content: space-between;
        padding: 0 $remof16px;

        .btn-back {
            border: 0 none;
            background-color: transparent;
            padding: 0;
        }

        &.has-alert {
            border-top: $remof1px solid #e9e9e9;
        }        
    }

    &-info {
        
        .retry-container {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
        }
        
        .retry-inner-container {
            display: inline-flex;
            align-items: center;
            flex-wrap: wrap;
        }
        
        .ca-error-info-icon {
            width: pxtorem(32);
            height: pxtorem(32);
            background-color: #E9E9E9;
            border-radius: 50%;
            font-size: 1.75rem;
            color: #FFFFFF;
            font-weight: bolder;
            margin-right: 0.3125rem;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: -$remof14px;
        }
        
        .retry-text {
            margin-right: $remof5px;
            font-size: $remof18px;
        }
        
        .retry-button {
            border: $remof1px solid #047a9c;
            cursor: pointer;
            padding: $remof5px;
            font-weight: 600;
            font-size: $remof12px;
            color: #047a9c;
            margin-right: $remof5px;
            background-color: transparent;
            margin-top: -$remof12px;
        }
         
        h3 {
            color: #000000;
            font-weight: normal;
            font-size: $remof24px;
            line-height: pxtorem(28);
        }

        .course-info {
            color: #000000;
            font-size: $remof14px;
            line-height: $remof18px;
            margin: 0;
        }

        .btn-email {
            background: #ffffff;
            border: $remof1px solid #6a7070;
            border-radius: pxtorem(40);
            color: #6a7070;
            font-weight: 600;
            font-size: $remof16px;
            line-height: $remof20px;
            height: pxtorem(36);
            text-align: center;
            width: pxtorem(180);
        }
        
        .disabled-email-btn {
            opacity: 0.3;
        }
        
        .disabled-email-btn:hover {
            cursor: not-allowed;
        }

        & > .row {
            align-items: center;
            margin: 0;

            & > div {
                padding: 0;
            }
        }
    }

    &-performance {
        margin-top: pxtorem(60);
    }

    &-topics {
        border-top: $remof1px solid #D9D9D9;
        padding-top: pxtorem(55);
    }
}

.student-tag-list {
    font-size: $remof14px;
    line-height: $remof18px;
    display: flex;
    align-items: center;
    width: auto;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #252525;
    padding-top: pxtorem(7);
}

.student-detail-tag-icon {
    width: $remof20px;
    height: $remof20px;
    margin-right: pxtorem(3);
}
