@import'../../common/mixins.scss';

@media screen and (-webkit-min-device-pixel-ratio:0) { 
     /* Safari only override */
     .vz-custom-tooltip-container {
        .vz-custom-tooltip {
            display: none;
        }
        &:hover,
        &:focus {
            outline: none;
            .vz-custom-tooltip {
                display: block;
            }
        }
        &:focus > *:first-child {
            outline: 0.125rem solid #0b73da;
            outline-offset: 0.25rem;
            box-shadow: none;  
        }
    }
}

.vz-custom-tooltip-container {
    display: inline-flex;
    position: relative;
    white-space: nowrap;
}

.vz-custom-tooltip {
    display: block;
    position: absolute;
    opacity: 0;
    transition: opacity 0.15s linear;
    visibility: hidden;
    z-index: 1070;
}

.vz-custom-tooltip-container:hover .vz-custom-tooltip,
.vz-custom-tooltip-container:focus .vz-custom-tooltip {
    opacity: 0.9;
    visibility: visible;
}

.vz-custom-tooltip-content {
    font-size: pxtorem(13);
    background-color: #000;
    border-radius: 0.25rem;
    color: #fff;
    max-width: pxtorem(1280);
    padding: $remof10px $remof15px;
}

.vz-custom-tooltip-content .mb-3 span {
    display: block;
    width: pxtorem(260);
    white-space: normal;
    text-align: left;
}

.vz-custom-tooltip-content .content span {
    display: block;
    width: pxtorem(260);
    white-space: normal;
    text-align: left;
}

.vz-custom-tooltip.top {
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    padding: $remof5px 0;
}

.vz-custom-tooltip.bottom {
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    padding: $remof5px 0;
}

.vz-custom-tooltip.left {
    right: 100%;
    top: 50%;
    transform: translateY(-50%);
    padding: 0 $remof5px;
}

.vz-custom-tooltip.right {
    left: 100%;
    top: 50%;
    transform: translateY(-50%);
    padding: 0 $remof5px;
}

.vz-custom-tooltip.top .vz-custom-tooltip-arrow {
    top: auto;
    bottom: 0;
    left: 50%;
    margin-left: -$remof5px;
    border-width: $remof5px $remof5px 0;
    border-top-color: #000;
}

.vz-custom-tooltip.bottom .vz-custom-tooltip-arrow {
    top: 0;
    left: 50%;
    margin-left: -$remof5px;
    border-width: 0 $remof5px $remof5px;
    border-bottom-color: #000;
}

.vz-custom-tooltip.left .vz-custom-tooltip-arrow {
    top: 50%;
    margin-top: -$remof5px;
    border-width: $remof5px 0 $remof5px $remof5px;
    border-left-color: #000;
    right: 0;
    left: auto;
}

.vz-custom-tooltip.right .vz-custom-tooltip-arrow {
    top: 50%;
    left: auto;
    margin-left: -$remof5px;
    border-width: $remof5px $remof5px $remof5px 0;
    border-right-color: #000;
    transform: translateY(-50%);
}

.vz-custom-tooltip .vz-custom-tooltip-arrow {
    position: absolute;
    width: 0;
    height: 0;
    border-color: transparent;
    border-right-color: transparent;
    border-style: solid;
}
