@import "../../common/mixins.scss";

.ca-nocourse-error {
    text-align: center;
    background-color: #f5f5f5;
    min-height: pxtorem(850);
    padding: pxtorem(100) 0;
    position: relative;
    height: 100%;
    width: 100%;

    .catext-collecting-data {
        font-size: pxtorem(20);
        font-weight: 600;
        color: $txtColor;
    }

    .catext-check-back {
        font-size: $remof14px;
        font-weight: normal;
        line-height: $remof18px;
        color: $txtColor;
    }

    .catext-no-data-after {
        font-size: $remof12px;
        line-height: $remof20px;
        color: $txtColor;
        margin-bottom: $remof14px;
        margin-top: 0;
    }

    .caimg-woman-chair {
        margin: pxtorem(50) 0;
        width: 35%;
    }

    & > .row {
        margin: 0;
        align-items: center;
        justify-content: center;
    }
}
