@import "../../common/mixins.scss";

.progressbar-tooltip .tooltip-inner {
    max-width: pxtorem(135);
    font-size: $remof12px;
    font-weight: 400;
    line-height: $remof18px;
    color: #ffffff;
    padding: pxtorem(8) $remof12px;
    text-align: left;

    .title {
        font-weight: 700;
    }
}

.topics-performance {
    .ca-error-message {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 150px;
        .ca-error-info-icon {
            width: 40px;
            height: 40px;
            background-color: #E9E9E9;
            border-radius: 50%;
            text-align: center;
            font-size: 28px;
            color: #FFFFFF;
            font-weight: bolder;
            margin-left: 22px;
            margin-bottom: 5px;
        }
        .ca-error-content {
            margin: 0 5px;
        }
    }
    h4 {
        margin-bottom: pxtorem(40);
        margin-left: pxtorem(20);
        font-size: $remof18px;
        line-height: $remof18px;
        color: #252525;
    }

    .progress-container {
        @include flexbox;
        @include flexboxAlignCenter;
        margin-bottom: pxtorem(10);

        .progress-title {
            @include fontFamily;
            width: pxtorem(120);
            text-align: right;
            margin-right: 10px;
            line-height: 1.125rem;
            font-size: pxtorem(14);
            text-decoration: underline;
            color: #007fa3;
            cursor: pointer;
        }

        .progress {
            width: 50%;
            background-color: #fff;
            overflow: visible;
            @include flexbox;
            @include flexboxAlignCenter;

            .progressPercent {
                margin-left: pxtorem(10);
            }

            &-bar {
                border-radius: pxtorem(2);
                height: pxtorem(13);
                position: relative;
                overflow: visible;

                .label {
                    @include fontFamily;
                    position: absolute;
                    right: pxtorem(-32);
                    top: pxtorem(-3);
                    font-weight: 600;
                    font-size: pxtorem(14);
                    line-height: pxtorem(18);
                    color: #252525;
                }

                &.bg-success {
                    background-color: $bgSuccess !important;
                }

                &.bg-warning {
                    background-color: $bgWarning !important;
                }

                &.bg-danger {
                    background-color: $bgDanger !important;
                }

                &.bg-info {
                    background-color: #e5e5e5 !important;
                    box-shadow: inset 0 0 0 rgba(0, 0, 0, 0.25);
                }
            }
        }

        &.faded-progress {
            .progress-bar {
                &.bg-success {
                    background-color: rgba(3, 130, 56, 0.64) !important;
                }

                &.bg-warning {
                    background-color: rgba(255, 184, 28, 0.64) !important;
                }

                &.bg-danger {
                    background-color: rgba(202, 70, 68, 0.64) !important;
                }

                &.bg-info {
                    background-color: #e5e5e5 !important;
                    box-shadow: inset 0 0 pxtorem(4) rgba(0, 0, 0, 0.25);
                }
            }
        }
    }
}
