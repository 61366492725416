@function pxtorem($pxVal) {
    @return $pxVal * 0.0625rem;
}

@mixin flexbox {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
}

@mixin textEllipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

@mixin flexboxAlignCenter {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

@mixin justifyContentCenter {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

@mixin fontFamily {
    font-family: Open Sans, sans-serif;
    font-style: normal;
    font-weight: normal;
}

$remof80px: pxtorem(80);
$remof40px: pxtorem(40);
$remof36px: pxtorem(36);
$remof30px: pxtorem(30);
$remof28px: pxtorem(28);
$remof25px: pxtorem(25);
$remof24px: pxtorem(24);
$remof20px: pxtorem(20);
$remof18px: pxtorem(18);
$remof16px: pxtorem(16);
$remof15px: pxtorem(15);
$remof14px: pxtorem(14);
$remof12px: pxtorem(12);
$remof10px: pxtorem(10);
$remof5px: pxtorem(5);
$remof4px: pxtorem(4);
$remof2px: pxtorem(2);
$remof1px: pxtorem(1);

$bgWarning: #ffb81c;
$bgDanger: #ca4644;
$bgSuccess: #038238;

$txtColor: #252525;
$shadowBgColor: #efefef;
