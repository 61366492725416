@import '../../common/mixins.scss';

.student-container {
    .student-distribution {
        .bars-wrapper {
            rect {
                cursor: pointer;
            }
        }
    }
}