@import "../../common/mixins.scss";

div.exclamation {
    border-radius: 50%;
    width: pxtorem(40);
    height: pxtorem(40);
    color: white;
    background-color: grey;
}
circle.exclamation-circle {
    fill: #e9e9e9;
}

div.tooltip.histogram-tooltip {
    @include flexbox;
    @include flexboxAlignCenter;
    position: absolute;
    z-index: 2;
    width: auto;
    height: pxtorem(36);
    opacity: 0.88;
    border-radius: $remof4px;
    border: solid $remof1px #c7c7c7;
    background-color: #252525;
    pointer-events: none;
    overflow-wrap: break-word;

    font-weight: 600;
    font-size: $remof12px;
    line-height: $remof18px;
    text-align: center;
    color: #ffffff;
    padding: 0 pxtorem(4);
    &::after {
        content: "";
        position: absolute;
        left: 42%;
        top: 100%;
        width: 0;
        height: 0;
        border-left: pxtorem(7) solid transparent;
        border-right: pxtorem(7) solid transparent;
        border-top: pxtorem(7) solid #252525;
        clear: both;
    }

    div.score-range {
        font-size: $remof14px;
        line-height: pxtorem(21);
        font-weight: bold;
    }

    div.tooltip-count {
        font-size: $remof12px;
        line-height: $remof18px;
    }
}

.o-score-histogram-wrapper {
    position: relative;

    text {
        cursor: auto;
    }

    .o-score-histogram-title {
        font-size: $remof16px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.25;
        letter-spacing: normal;
        color: #252525;
        margin-bottom: $remof15px;
    }

    g.bars-wrapper rect {
        opacity: 1;
        fill: #047a9c;

        &.distro-noattempts {
            fill: #6a7070;
        }

        &.inactive {
            opacity: 0.3;
        }
    }

    g.tick text {
        fill: #252525;
        font-size: $remof12px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: $remof18px;
        letter-spacing: normal;
    }

    g.Axis-Bottom {
        path,
        g.tick line {
            stroke: #a6a9a9;
        }

        g.tick:nth-child(2) line {
            display: none;
        }
    }

    g.Axis-Left {
        path {
            display: none;
        }

        g.tick line {
            stroke: #e9e9e9;
            stroke-width: $remof1px;
        }

        g.tick:nth-child(2) line {
            stroke: #a6a9a9;
            stroke-width: $remof1px;
        }

        text.hide-tick {
            display: none;
        }
    }

    path.avg-score-text-box {
        stroke: #e9e9e9;
        stroke-width: $remof1px;
        fill: #f5f5f5;
    }

    text.avg-score-text {
        font-size: $remof12px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.42;
        letter-spacing: normal;
        fill: #252525;
    }

    line.avg-score-marker {
        stroke: #000;
        stroke-width: $remof1px;
        stroke-dasharray: 4;
    }

    text.x-axis-label,
    text.y-axis-label {
        fill: #6a7070;
        font-size: $remof12px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.33;
        letter-spacing: normal;
        text-anchor: middle;
    }

    text.noattp-info {
        fill: #252525;
        font-size: $remof12px;
    }

    text.no-data-label {
        fill: #6a7070;
        font-size: $remof14px;
        line-height: $remof16px;
        text-anchor: middle;
    }

    text.failed-label {
        fill: #6a7070;
        font-size: $remof14px;
        line-height: 1.14;
        text-anchor: middle;
    }

    text.exclamation-icon {
        font-size: pxtorem(30);
        stroke: #fff;
        fill: #fff;
        text-anchor: middle;
    }

    .o-score-histogram-controller {
        width: pxtorem(120);
        padding-right: pxtorem(30);
        font-size: $remof12px;
        float: right;

        span {
            float: right;
        }

        div input {
            width: 100%;
        }
    }
}
