@import "../../common/mixins.scss";

.ca-app-container {
    header {
        background-color: #f1f1f1;
        margin-bottom: 0;

        a {
            border-bottom: $remof5px solid transparent;
            display: inline-block;
            padding: 0.5rem 1rem;
            text-align: center;
            text-decoration: none;
            width: pxtorem(125);

            &.active,
            &:hover {
                border-bottom: $remof5px solid #000;
                font-weight: 600;
            }
        }

        .btn-logout {
            border: 0 none;
            border-bottom: $remof5px solid transparent;
            background-color: transparent;
            color: #047a9c;
            font-size: 1rem;
            float: right;
            line-height: $remof24px;
            padding: 0.5rem 1rem;
            text-align: center;
            width: pxtorem(125);

            &:hover {
                border-bottom: $remof5px solid #000;
                font-weight: 600;
            }
        }
    }

    nav.ca-alerts {
        * {
            color: inherit;
            font-size: $remof14px;
            font-family: inherit;
            line-height: $remof18px;
            margin: 0;
        }

        .alert {
            margin: $remof5px;
        }

        a:hover {
            text-decoration: none;
        }
    }

    .btn-search {
        width: $remof24px;
        height: $remof24px;
        background-image: url("../../images/search-18-black.svg");
        background-repeat: no-repeat;
        border: none;
        outline: none;
        background-color: #fff;
        background-position: center;
        cursor: pointer;
        vertical-align: middle;
        background-size: contain;

        &:disabled {
            opacity: 0.6;
        }
    }

    .btn-setting {
        width: $remof24px;
        height: $remof24px;
        background-image: url("../../images/settings-18.svg");
        background-repeat: no-repeat;
        border: none;
        outline: none;
        background-color: #fff;
        background-position: center;
        cursor: pointer;
        vertical-align: middle;
        background-size: contain;

        &:disabled {
            opacity: 0.6;
        }
    }

    .no-data-message {
        @include flexbox;
        align-items: center;
        border-bottom: 1px solid #e9e9e9;
        justify-content: center;
        height: pxtorem(150);
        width: 100%;
    }

    .styled-knowledge-score {
        @include flexbox;
        @include flexboxAlignCenter;
        @include justifyContentCenter;

        font-size: $remof14px;
        line-height: $remof18px;
        min-width: pxtorem(32);
        height: pxtorem(23);
        border-radius: pxtorem(8);
        text-align: center;
        background: rgba(193, 197, 194, 0.938);
        padding: 0 $remof4px * 2;

        &.average {
            background: rgba(255, 184, 28, 0.64);
        }

        &.below-average {
            background: rgba(202, 70, 68, 0.64);
        }

        &.above-average {
            background: rgba(3, 130, 56, 0.64);
        }
    }

    #compose_email {
        .modal-content {
            width: pxtorem(600);
            .description {
                border: none;
            }
            .button-box {
                padding: $remof20px 0 $remof5px;
                .squaredBox {
                    span {
                        color: #252525;
                        font-size: pxtorem(14);
                    }

                    input[type="checkbox"] {
                        &:checked + label:before {
                            border: pxtorem(2) solid #12a5a3;
                        }
                    }

                    label:before {
                        border: pxtorem(2) solid #c7c7c7;
                        border-radius: pxtorem(4);
                    }
                }
                .button-box-ctr {
                    .button {
                        width: pxtorem(152);
                        height: pxtorem(36);
                        padding: pxtorem(0) pxtorem(24);
                        border-radius: pxtorem(40);
                        font-weight: 600;
                        font-size: pxtorem(16);
                        line-height: pxtorem(20);
                        margin-left: pxtorem(10);
                    }
                    .discard {
                        color: #6a7070;
                        border: none;
                    }
                    .email-submit {
                        width: pxtorem(152) !important;
                    }
                }
            }
        }
        .content-division {
            border-bottom: pxtorem(2) solid #e0e0e0;
            textarea {
                resize: none;
            }
            input {
                width: 89%;
            }
            .tobox {
                .toName {
                    color: #252525;
                }
                .removeIcon {
                    color: #6a7070;
                    font-size: pxtorem(16);
                    font-weight: 600;
                }
            }
        }
    }

    .ca-error-message {
        display: flex;
        justify-content: center;
        align-items: center;
        height: pxtorem(100);

        .ca-error-content {
            color: #252525;
            font-size: $remof14px;
            line-height: $remof18px;
            margin: 0 $remof5px;
        }

        .ca-error-info-icon {
            width: $remof40px;
            height: $remof40px;
            background-color: #E9E9E9;
            border-radius: 50%;
            text-align: center;
            font-size: pxtorem(28);
            color: #FFFFFF;
            font-weight: bolder;
            margin-right: $remof5px;
        }
    }

    .btn-export {
        width: $remof24px;
        height: $remof24px;
        background-image: url("../../images/download-24.svg");
        background-repeat: no-repeat;
        border: none;
        outline: none;
        background-color: #fff;
        background-position: center;
        cursor: pointer;
        vertical-align: middle;
        background-size: contain;
    
        &.disabled,
        &:disabled {
            pointer-events: none;
            opacity: 0.3;
        }
    }

    .infoContent {
        white-space: normal;
        width: 21rem;
        display: inline-block;
        font-size: 0.875rem;
        line-height: 1.125rem;
        padding: 0.625rem 0.9375rem;
    }

    .visibility-hidden {
        visibility: hidden;
        width: 0;
        height: 0;
        overflow: hidden;
    }
}

.common-tooltip .tooltip-inner {
    max-width: pxtorem(260);
    font-size: $remof14px;
    font-weight: normal;
    line-height: $remof18px;
    color: #ffffff;
    padding: $remof10px $remof15px;
    text-align: left;
    border: none;
}

.heading-h1 {
    color: #000000;
    font-size: pxtorem(28);
    font-weight: 400;
    line-height: $remof18px;
    margin: 0;
}

.heading-h2{
    font-size: pxtorem(22.88);
    color: #252525;
    margin: 0;
    font-weight: 400;
}