@import "../../common/mixins.scss";

.custom-filter {
    position: relative;

    &-scroll-wrapper {
        align-items: center;
        display: flex;
        border-radius: pxtorem(21);
        height: pxtorem(50);
        width: 100%;
        margin: 0 auto;
        vertical-align: middle;
        scrollbar-width: none; /* Firefox */
        -ms-overflow-style: none; /* IE 10+ */

        &.has-scroll {
            overflow-x: auto;
            width: 98%;

            .custom-filter-scroll {
                padding: 0 pxtorem(35);
            }
        }

        &::-webkit-scrollbar {
            height: 0px;
            background: transparent;
        }

        &:not(.has-scroll) .custom-filter-edit {
            max-width: pxtorem(230);

            .filter-edit-title {
                @include textEllipsis;
                display: inline-block;
                max-width: calc(100% - 1.875rem);
            }
        }
    }

    &-scroll {
        white-space: nowrap;
    }

    &-add {
        background: rgba(217, 217, 217, 0.0001);
        border: $remof1px dashed #6a7070;
        border-radius: pxtorem(40);
        font-size: $remof14px;
        font-weight: 600;
        line-height: $remof18px;
        height: pxtorem(32);
        padding: pxtorem(7) pxtorem(16);

        &:hover,
        &-active {
            background-color: #f5f5f5;
            border: 1px dashed #252525;
        }
    }

    &-edit {
        background: #047a9c;
        border: 0 none;
        border-radius: pxtorem(40);
        color: #fff;
        font-size: $remof14px;
        font-weight: 600;
        line-height: $remof20px;
        height: pxtorem(32);
        margin-right: pxtorem(12);
        padding: pxtorem(6) pxtorem(12);
        display: inline-block;
        vertical-align: middle;

        & > span {
            cursor: pointer;
            margin-left: pxtorem(7);

            &:not(.filter-edit-title) {
                vertical-align: middle;
            }
        }

        &:hover,
        &-active {
            background-color: #005a70;
        }
    }

    &-more {
        background: #f5f5f5;
        border: 0 none;
        border-radius: pxtorem(40);
        color: #252525;
        font-size: $remof14px;
        font-weight: 600;
        line-height: $remof20px;
        height: pxtorem(32);
        margin-right: pxtorem(12);
        padding: 0 pxtorem(12);

        &:hover {
            background-color: #c7c7c7;
        }
    }

    &-back,
    &-next {
        background-color: #ffffff;
        border: solid 1px #c7c7c7;
        border-radius: pxtorem(16);
        color: #6a7070;
        cursor: pointer;
        display: flex;
        height: pxtorem(34);
        left: 0;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: pxtorem(34);
        z-index: 1;

        & > svg {
            margin: auto;
            vertical-align: sub;
        }
    }

    &-next {
        left: auto;
        right: 0;
    }

    &-clear {
        background-color: transparent;
        border: 0 none;
        font-size: $remof14px;
        line-height: $remof18px;
        outline: none;
        text-align: center;
        width: $remof80px;

        &[disabled] {
            color: #6a7070;
        }
    }

    &-apply {
        background-color: #005d83;
        border: 0 none;
        border-radius: $remof40px;
        color: #ffffff;
        font-size: $remof14px;
        font-weight: 600;
        height: pxtorem(32);
        line-height: $remof18px;
        outline: none;
        padding: 0px $remof24px;
        text-align: center;
        width: pxtorem(128);

        &:hover {
            background-color: #005a70;
        }

        &[disabled] {
            background-color: #e9e9e9 !important;
        }
    }

    &-search {
        margin: $remof15px 0;
        padding: 0 $remof20px;
        position: relative;

        &-icon {
            position: absolute;
            width: $remof15px;
            height: $remof15px;
            color: #6a7070;
            top: 50%;
            transform: translateY(50%);
            margin-top: -$remof15px;
            background-image: url("../../images/search-18-black.svg");
            background-repeat: no-repeat;
            background-size: contain;
        }

        &-box {
            width: 100%;
            outline: none !important;
            padding: $remof5px $remof10px $remof5px pxtorem(25);
            border: 0 none;
            border-bottom: $remof1px solid #c7c7c7;

            &:focus {
                border-bottom: $remof1px solid #047a9c;
                box-shadow: 0 $remof1px $remof2px -$remof2px #fbe5f1;
            }

            &::placeholder {
                font-size: pxtorem(12);
                font-weight: normal;
                line-height: pxtorem(16);
                text-align: left;
                color: #000;
            }
        }
    }

    &-popover-container {
        background-color: #ffffff;
        border: 0 none;
        border-radius: pxtorem(8);
        box-shadow: 0 $remof2px $remof4px 0 rgba(0, 0, 0, 0.16), 0 $remof1px $remof1px 0 rgba(0, 0, 0, 0.1);
        padding: pxtorem(22) 0;
        min-width: pxtorem(275);
        max-width: pxtorem(390);

        .arrow {
            display: none;
        }
    }

    &-popover {
        &-head {
            margin-bottom: pxtorem(8);
            padding: 0 $remof20px;
        }

        &-action {
            svg {
                vertical-align: sub;
            }

            & + .custom-filter-popover-label {
                padding-left: $remof10px;
            }
        }

        &-label {
            color: #000;
            font-size: pxtorem(16);
            font-weight: 600;
            line-height: $remof18px;
            width: calc(100% - 1.125rem);
        }

        &-body {
            max-height: pxtorem(250);
            overflow-y: auto;

            .no-data-div {
                font-size: $remof14px;
                line-height: $remof18px;
                padding: 0 $remof20px;
                text-align: center;
            }

            .custom-checkbox {
                align-items: center;
                height: pxtorem(35);
                margin: 0;
                padding-left: pxtorem(28);
                padding-right: $remof5px;
                width: calc(100% - 1.125rem);

                & * {
                    cursor: pointer;
                }

                .custom-control-label {
                    color: #252525;
                    font-size: $remof14px;
                    line-height: pxtorem(19);
                    padding: pxtorem(6) 0;
                    width: 100%;

                    .cfilter-form-label {
                        @include textEllipsis;
                        display: inline-block;
                        width: 100%;
                    }

                    &::before {
                        border: $remof1px solid #ccc;
                        top: pxtorem(6);
                        left: pxtorem(-28);
                        height: $remof20px;
                        width: $remof20px;
                    }
                }

                input[type="checkbox"] {
                    &:focus ~ .custom-control-label::before {
                        box-shadow: none;
                        outline: none !important;
                        outline-offset: 0;
                    }

                    &:checked ~ .custom-control-label {
                        &::before {
                            background-color: #047a9c;
                            border: $remof1px solid #047a9c;
                            border-radius: $remof4px;
                        }

                        &::after {
                            background-size: 60%;
                            left: pxtorem(-28);
                            height: $remof20px;
                            top: pxtorem(6);
                            width: $remof20px;
                        }
                    }

                    &:indeterminate ~ .custom-control-label {
                        &::before {
                            background-color: #047a9c;
                            border: $remof1px solid #047a9c;
                            border-radius: $remof4px;
                        }

                        &::after {
                            background-size: 60%;
                            height: $remof20px;
                            left: pxtorem(-28);
                            top: pxtorem(6);
                            width: $remof20px;
                        }
                    }
                }
            }
        }

        &-footer {
            align-items: center;
            display: flex;
            justify-content: space-around;
            padding: $remof20px $remof20px 0;
        }

        &-item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: pxtorem(35);
            padding: 0 $remof20px;
            width: 100%;

            span svg {
                vertical-align: sub;
            }

            &:hover {
                background-color: #f5f5f5;
            }
        }

        .svg-color-prev-next {
            color: #6a7070;
            cursor: pointer;
        }
    }
}
