@import "../../common/mixins.scss";

.topics-container {
    padding: pxtorem(12) pxtorem(30);

    @import "./topics-list.scss";

    .topics-title {
        height: pxtorem(28);

        .help-icon {
            color: #6a7070;
            display: inline-block;
            vertical-align: middle;
        }
    }

    .topics-overview {
        margin-top: pxtorem(36);

        &-title {
            font-size: pxtorem(20);
            color: #252525;
            margin-bottom: pxtorem(16);
        }

        &-description {
            font-size: pxtorem(14);
            color: #252525;
        }
    }

    .topics-info {
        border-bottom: $remof1px solid #d9d9d9;
        margin-top: pxtorem(40);
    }

    .topics-filter {
        margin-bottom: $remof20px;

        & > .row {
            align-items: center;
        }
    }

    .topics-header {
        margin-bottom: pxtorem(10);
    }

    .highlight-text {
        text-decoration: underline;
    }
}
.modal {
    .modal-dialog {
        max-width: pxtorem(665);
    }
}

.coachmark-title {
    font-weight: 600;
    font-size: $remof14px;
    line-height: $remof18px;
    color: #252525;
}

.coachmark-text {
    font-size: $remof14px;
    line-height: $remof18px;
    color: #252525;
}
