@import "../../common/mixins.scss";

.student-container {
    padding: pxtorem(12) pxtorem(30);
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;

    .student-list {
        &-title {
            height: pxtorem(28);
            margin-bottom: pxtorem(12) !important;

            .help-icon {
                color: #6a7070;
                display: inline-block;
                vertical-align: middle;
            }
        }

        .button-email {
            height: pxtorem(36);
            background: #e9e9e9;
            border: pxtorem(1) solid #c7c7c7;
            color: #252525;
            font-weight: 600;
            font-size: pxtorem(14);
            line-height: pxtorem(18);
            border-radius: 0;
            &:focus {
                box-shadow: none;
                outline: pxtorem(2) solid #0b73da;
                outline-offset: pxtorem(4);
            }
            &:disabled {
                opacity: 0.6;
            }
        }
        .email {
            background: #e9e9e9;
            border: $remof1px solid #c7c7c7;
            box-sizing: border-box;
            width: pxtorem(122);
            height: pxtorem(36);
            padding: pxtorem(9) pxtorem(12);
            font-weight: 600;
            font-size: pxtorem(14);
            line-height: pxtorem(18);
            color: #252525;
            &.disable {
                opacity: 0.6;
                pointer-events: none;
            }
        }
        &-header {
            margin-bottom: pxtorem(21);
            margin-top: pxtorem(21);
        }
        &-info {
            @include flexbox;
            align-items: center;
            
            h3,
            h5 {
                margin-top: 0;
                margin-bottom: 0;
            }
        }
        &-actions {
            @include flexbox;
            align-items: center;
            height: pxtorem(35);
            justify-content: flex-end;

            button:not(:last-child) {
                margin-right: $remof10px;
            }

            .custom-search {
                display: inline-block;
                margin-right: $remof10px;
            }
            
            .alert-pii-error {
                margin-right: $remof10px;
            }
        }
        &-grid {
            .label {
                font-size: $remof14px;
                line-height: $remof18px;
                text-decoration: underline;
                color: #007fa3;
                cursor: pointer;
            }

            .progress {
                height: pxtorem(13);
                overflow: visible;
                width: pxtorem(150);

                &-bar {
                    height: pxtorem(13);
                    overflow: visible;
                    position: relative;
                    .bs-tooltip-top {
                        margin-bottom: $remof25px;
                        .arrow {
                            display: block;
                            width: 0.8rem;
                            height: 0.4rem;
                        }
                    }
                    &:first-child {
                        border-top-left-radius: $remof2px;
                        border-bottom-left-radius: $remof2px;
                    }
                    &:last-child {
                        border-top-right-radius: $remof2px;
                        border-bottom-right-radius: $remof2px;
                    }
                    &.bg-danger {
                        background-color: $bgDanger !important;
                    }
                    &.bg-warning {
                        background-color: $bgWarning !important;
                    }
                    &.bg-success {
                        background-color: $bgSuccess !important;
                    }
                }
            }
            &.grid-check-box.row {
                margin: 0px;
            }
            .custom-table {
                thead {
                    height: pxtorem(40);
                }
                th {
                    .custom-table-hcol {
                        @include flexbox;
                        @include flexboxAlignCenter;
                    }
                }
                .resizer {
                    bottom: 35%;
                }
            }
            .ca-error {
                margin: auto;
            }
        }

        &-filter {
            margin-bottom: $remof20px;
        }

        .tag-pop-over-label {
            width: $remof20px;
            background-image: url("../../images/label-18.svg");
            background-repeat: no-repeat;
            display: inline-block;
            height: $remof20px;
            vertical-align: middle;
            margin-left:$remof10px;
            bottom: $remof2px;
        }

        .popover {
            padding: $remof1px;
            font-style: normal;
            font-weight: 400;
            line-break: auto;
            line-height: $remof20px;
            text-align: start;
            text-decoration: none;
            text-shadow: none;
            text-transform: none;
            letter-spacing: normal;
            word-break: normal;
            word-spacing: normal;
            word-wrap: normal;
            white-space: normal;
            font-size: $remof14px;
            background-color: #fff;
            background-clip: padding-box;
            border: $remof1px solid rgba(0, 0, 0, 0.2);
            border-radius: pxtorem(6);
            -webkit-box-shadow: 0 $remof5px $remof10px rgba(0, 0, 0, 0.2);
            box-shadow: 0 $remof5px $remof10px rgba(0, 0, 0, 0.2);

            .arrow,
            .arrow:after {
                padding: 0px !important;
            }

            .tag-content {
                padding:  pxtorem(9) $remof14px;
                strong {
                    color: #007fa3;
                }
                ul {
                    list-style-type: none;
                    padding-left: 0px;
                }
                li {
                    color: #0000ee;
                    text-decoration: none;
                    cursor: pointer;
                }
            }
        }

        .emailContainer {
            overflow-y: hidden;

            .modal-dialog {
                vertical-align: top !important;
                top: pxtorem(30);
            }
        }
    }

    .emailContainer{
        z-index: 1200;
    }
}
