@import "../../common/mixins.scss";

.custom-dropdown {
    .dropdown-activator,
    .dropdown-item {
        font-size: $remof14px;
        line-height: $remof18px;
        color: #252525;
    }
    
    .dropdown-activator {
        &::after {
            display: none;
        }
    }

    .dropdown-menu {
        border: $remof1px solid #c7c7c7;
        border-radius: $remof2px;

        .list-unstyled {
            margin: 0;
        }
    }

    .dropdown-item {
        padding: 0;

        button {
            border: 0;
            background: transparent;
            padding: $remof4px $remof16px;
            text-align: left;
            width: 100%;
        }

        &.active {
            background-color: #fff;
        }

        &:hover {
            background-color: #e9e9e9;
        }
    }

    .icon {
        color: #6a7070;
    }

    .icon-padding {
        padding-left: $remof4px * 2;
    }
}
