.topics-list {
    margin-top: pxtorem(31);

    &-header {
        margin-bottom: pxtorem(30);
    }

    &-info {
        @include flexbox;
        align-items: center;

        h3 {
            color: #000000;
            font-size: $remof24px;
            font-weight: 600;
            line-height: $remof18px;
            margin-bottom: 0;
        }
    }

    &-actions {
        @include flexbox;
        align-items: center;
        height: 35px;
        justify-content: flex-end;

        button:not(:last-child) {
            margin-right: $remof10px;
        }

        .custom-search {
            display: inline-block;
            margin-right: $remof10px;
        }
    }

    &-grid {
        .progress {
            height: pxtorem(13);
            overflow: visible;
            width: pxtorem(150);

            &-bar {
                height: pxtorem(13);
                overflow: visible;
                position: relative;

                &.bg-danger {
                    background-color: $bgDanger !important;
                }

                &.bg-warning {
                    background-color: $bgWarning !important;
                }

                &.bg-success {
                    background-color: $bgSuccess !important;
                }

                &:first-child {
                    border-top-left-radius: $remof2px;
                    border-bottom-left-radius: $remof2px;
                }

                &:last-child {
                    border-top-right-radius: $remof2px;
                    border-bottom-right-radius: $remof2px;
                }
            }
        }

        .topic-grid-title {
            @include textEllipsis;
            display: inline-block;
            width: 100%;
        }

        .has-link{
            color: #007FA3;
        }
    }
}
