@import "../../common/mixins.scss";

.custom-search {
    position: relative;

    .clear-searchtext {
        &:focus {
            outline: #0b73da solid $remof2px !important;
            outline-offset: $remof4px;
        }
    }

    .custom-search-input {
        border: $remof1px solid #aaa;
        border-radius: 0.25rem;
        border-left: none;
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
        font-size: $remof14px;
        line-height: $remof18px;
        height: pxtorem(35);
        padding: 0px pxtorem(30) 0px $remof5px;

        &:focus,
        &:active {
            box-shadow: none;
            outline: none;
        }

        &::-ms-clear {
            // removing extra close button in IE
            display: none !important;
        }
    }

    .input-group {
        &-prepend {
            font-size: $remof14px;
            line-height: $remof18px;
            color: #555555;
            text-align: center;
            background-color: #fff;
            border: $remof1px solid #aaa;
            border-radius: 0.25rem;
            border-right: none;
            margin-right: -$remof2px;
        }

        &-text {
            background-color: #fff;
            border: none;
            opacity: 0.7;
        }
    }

    .dropdown-menu {
        font-size: 1rem;
        max-height: pxtorem(200);
        overflow-y: auto;
        padding: 0;
        width: 100%;

        .dropdown-item {
            border-bottom: pxtorem(1) solid rgb(233, 233, 233);
            .dropdown-item-1 {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                max-width: pxtorem(237);
                font-size: pxtorem(14) !important;
                .highlight-text {
                    text-decoration: underline;
                }
            }

            .dropdown-item-2 {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                max-width: pxtorem(237);
                font-size: pxtorem(12) !important;
                color: rgb(106, 112, 112);
            }
        }
        .dropdown-item-error {
            padding: pxtorem(5) pxtorem(15);
            color: #6a7070;
            font-size: pxtorem(14);
            text-align: center;
        }
    }
}

.custom-search-close {
    cursor: pointer;
    top: $remof4px;
    right: pxtorem(8);
    position: absolute;
    border: none;
    background: transparent;
    float: right;

    .close-icon {
        font-size: 1.5rem;
        opacity: 0.5;
        font-weight: bold;
        line-height: 1;
        text-shadow: 0 $remof1px 0 #fff;

        &:hover,
        &:focus {
            color: #000;
            text-decoration: none;
            filter: alpha(opacity=50);
            opacity: 1;
        }
    }
}

.custom-search-btn {
    margin-top: pxtorem(3);
    margin-bottom: pxtorem(3);
    width: $remof18px;
    height: $remof18px;
    background-image: url("../../images/search-18-black.svg");
    background-repeat: no-repeat;
    border: none;
    outline: none;
    background-color: #fff;
    background-position: center;
    cursor: pointer;
    vertical-align: middle;
    background-size: contain;

    &:focus {
        outline: #0b73da solid $remof2px !important;
        outline-offset: $remof4px;
    }

    &[disabled] {
        pointer-events: none;
        opacity: 0.3;
    }
}
