/* open-sans-regular - vietnamese_latin-ext_latin_hebrew_greek-ext_greek_cyrillic-ext_cyrillic */
@font-face {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    src: url("../fonts/open-sans-v27-eot-regular.eot"); /* IE9 Compat Modes */
    src: local(""), url("../fonts/open-sans-v27-eot-regular.eot?#iefix") format("embedded-opentype"),
        /* IE6-IE8 */ url("../fonts/open-sans-v27-woff2-regular.woff2") format("woff2"),
        /* Super Modern Browsers */ url("../fonts/open-sans-v27-woff-regular.woff") format("woff"),
        /* Modern Browsers */ url("../fonts/open-sans-v27-ttf-regular.ttf") format("truetype"),
        /* Safari, Android, iOS */ url("../fonts/open-sans-v27-svg-regular.svg#OpenSans") format("svg"); /* Legacy iOS */
}
