@import "../../common/mixins.scss";

%sort-common {
    cursor: pointer;
    display: inline-block;
    width: $remof15px;
    height: $remof15px;
}

.custom-table {
    th,
    td {
        @include flexbox;
        border-top: none;
        color: #252525;
        font-size: $remof14px;
        font-weight: normal;
        line-height: $remof18px;
    }

    th {
        border-bottom: $remof1px solid #c7c7c7;
        align-items: flex-end;

        & > span {
            white-space: nowrap;
        }

        .custom-table-hcol {
            display: flex;
            align-items: flex-end;
            justify-content: space-between;
            width: 100%;

            .hcol-title {
                flex-shrink: 4;
                white-space: pre-wrap;
                overflow: hidden;
            }

            .hcol-sort {
                margin-bottom: $remof4px;
            }
        }
    }

    td {
        border-bottom: $remof1px solid #e9e9e9;
    }

    .resizer {
        right: 0;
        background: #c7c7c7;
        width: $remof2px;
        height: 50%;
        position: absolute;
        bottom: 50%;
        touch-action: none;
        transform: translateY(50%);
        z-index: 1;

        &.isResizing {
            background: #6a7070;
        }
    }

    &-sort {
        @extend %sort-common;
        background-image: url("../../images/sortable-18.svg");
    }

    &-sorted-asc {
        @extend %sort-common;
        background-image: url("../../images/sort-up-18.svg");
    }

    &-sorted-desc {
        @extend %sort-common;
        background-image: url("../../images/sort-down-18.svg");
    }

    &-pagination {
        @include flexbox;
        align-items: center;
        width: 100%;

        .dropdown-container {
            height: $remof18px;

            button {
                vertical-align: top;

                div {
                    font-size: $remof14px;
                    line-height: $remof18px;
                    color: #252525;
                }
            }

            ul li {
                font-size: $remof14px;
                line-height: $remof18px;
                color: #252525;
            }
        }

        .custom-table-page-info {
            color: #252525;
            font-size: $remof14px;
            line-height: $remof18px;
            margin-left: pxtorem(30);
        }

        .custom-table-paginate {
            margin: 0 0 0 auto;

            nav button {
                span {
                    font-size: $remof14px;
                    line-height: $remof18px;
                    vertical-align: text-top;

                    svg {
                        vertical-align: middle;
                    }
                }
            }

            &.disable {
                nav {
                    button {
                        pointer-events: none;
                    }
                }
            }
        }
    }

    .ct-info-icon {
        color: #6a7070;
        margin: 0 $remof5px 0 auto;
    }

    .checkBox-container {
        min-width: $remof20px;
        position: relative;

        input[type="checkbox"] ~ span {
            height: $remof16px;
            width: $remof16px;
            line-height: $remof16px;
            text-align: center;

            svg {
                opacity: 0;
                height: $remof16px;
                width: $remof16px;
            }
        }

        input[type="checkbox"]:checked ~ span {
            background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAANCAIAAAAWvsgoAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAACiSURBVChTY7j14S2RiIDS7ulTzz9+AGHjU1pSW6OqqrpwwzoIF6fStXt3A9VZ29kSMBUobWBkBFS649RxuCB2pbEpyUB19V2dyIJQpYevX2no7oTYBfQKUF1QeBhECo6gSqctWQSUdvf2BtoItNrA0BCoGa4IghAOyCgsAKqGAKBOuDgcobgV4kQgiSwIRyhKgW4FmgcPHTSEohQ/Ilrph7cAdli5fKK0w8YAAAAASUVORK5CYII=);
            svg {
                opacity: 1;
            }
        }
    }

    thead {
        .checkBox-container {
            bottom: pxtorem(17);

            input[type="checkbox"] {
                z-index: 2;
            }
        }
    }

    tbody {
        tr {
            min-height: pxtorem(45);
            border-top: 0.0625rem solid white;
            border-bottom: 0.0625rem solid #e9e9e9;

            &.has-hover:hover {
                background-color: #e9e9e9;
                border-top: $remof1px solid #047a9c;
                border-bottom: $remof1px solid #047a9c;
                cursor: pointer;
            }

            &.rowSelected {
                background-color: #daf0ed;
                border-top: $remof1px solid #047a9c;
                border-bottom: $remof1px solid #047a9c;
            }
        }

        input {
            position: absolute;
        }

        td {
            border-bottom: none;
        }
    }
}

.grid-check-box {
    tr {
        th,
        td {
            &:first-child {
                width: $remof10px !important;
                flex: 10 0 auto !important;

                .resizer {
                    display: none;
                }
            }
        }
    }
}
