@import '../../common/mixins.scss';

.coach-mark-modal{
    .modal-dialog {
       display: flex;
       justify-content: center;
    }

    .modal-content{
        background: #FFFFFF;
        border: $remof1px solid #C4C4C4;
        border-radius: $remof4px;
        width: pxtorem(579);
    }

    .modal-body{
        padding: pxtorem(43) pxtorem(65) 0;

        :first-child img{
            margin-bottom: pxtorem(35);
        }

        .coach-mark-heading{
            font-weight: 600;
            font-size: $remof24px;
            line-height: $remof18px;
            text-align: center;
            color: #252525;
            margin-bottom:pxtorem(30);
        }

        .coach-mark-content{
            font-size: $remof12px;
            line-height: $remof20px;
            color: #252525;
            max-width: 100%;
            
            p{
                margin-bottom:pxtorem(12);
                font-size: $remof14px;
            }

            span{
                font-weight: 800;
            }

            ul{
                padding-left: pxtorem(25);
                font-size: $remof14px;
            }
        }
    }

    .modal-footer{
        border: none;
        padding-top: $remof10px;
        padding-bottom: pxtorem(30);

        button{
            padding: 0 $remof24px;
            width: pxtorem(152);
            height: pxtorem(36);
            background: rgba(217, 217, 217, 0.3);
            border: $remof1px solid #6A7070;
            border-radius: $remof40px;
            font-weight: 800;
        }
    }
}