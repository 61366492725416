@import "../../common/mixins.scss";

.no-data-error{
    background-color: #fff;
    height: pxtorem(540);
    text-align: center;

    img {
        height: pxtorem(120);
        margin: pxtorem(108) auto pxtorem(33);
        width: pxtorem(129);
    }

    &-line1 {
        color: #252525;
        font-size: $remof18px;
        line-height: normal;
        margin: 0 auto $remof24px;
        width: pxtorem(387);
    }

    &-line2 {
        color: #6a7070;
        font-size: $remof14px;
        line-height: normal;
        margin: 0 auto;
        width: pxtorem(387);
    }
}