@import "../../common/mixins.scss";

@keyframes skeleton {
    to {
        opacity: 0.5;
    }
}

.shadow-container {
    width: 100%;
    height: 100%;
    padding: $remof12px 0;

    .shadow-pagination {
        @include flexbox;
        @include flexboxAlignCenter;
        margin-top: $remof15px;
        justify-content: space-between;
        height: pxtorem(44);
        width: 100%;

        &-left,
        &-right {
            @include flexbox;
            @include flexboxAlignCenter;
            justify-content: space-between;
            height: pxtorem(44);
        }

        &-left {
            width: pxtorem(255);
        }

        &-right {
            width: pxtorem(150);
        }

        &-dropdown {
            width: pxtorem(75);
            height: $remof20px;
            background-color: $shadowBgColor;
            animation: skeleton 1s ease infinite alternate;
        }

        &-result {
            width: $remof80px * 2;
            height: $remof20px;
            background-color: $shadowBgColor;
            animation: skeleton 1s ease infinite alternate;
        }

        &-prev,
        &-value,
        &-next {
            height: $remof28px;
            width: $remof28px;
            background-color: $shadowBgColor;
            animation: skeleton 1s ease infinite alternate;
        }

        &-value {
            width: $remof20px !important;
        }

        &-prev,
        &-next {
            border-radius: 50%;
        }
    }

    .shadow-table {
        &-actions {
            @include flexbox;
            @include flexboxAlignCenter;
            justify-content: space-between;
            margin-bottom: $remof30px;
        }

        &-count {
            width: pxtorem(105);
            height: $remof36px;
            background-color: $shadowBgColor;
            animation: skeleton 1s ease infinite alternate;
        }

        &-buttons {
            @include flexbox;
            @include flexboxAlignCenter;
            width: pxtorem(62);
            height: $remof30px;
            justify-content: space-between;
        }

        &-button {
            width: $remof30px;
            height: $remof30px;
            background-color: $shadowBgColor;
            animation: skeleton 1s ease infinite alternate;
        }

        &-head {
            width: 100%;
            height: $remof40px + $remof5px;
            background-color: $shadowBgColor;
            animation: skeleton 1s ease infinite alternate;
            margin-bottom: $remof5px;
        }

        &-body {
            width: 100%;
            height: $remof36px;
            background-color: $shadowBgColor;
            animation: skeleton 1s ease infinite alternate;
            margin-bottom: $remof2px;
        }
    }

    .shadow-performance {
        margin-top: pxtorem(60);
        margin-bottom: pxtorem(55);

        .shadow-performance-info {
            background-color: $shadowBgColor;
            width: pxtorem(250);
            height: $remof18px;
            margin-bottom: $remof10px;
            animation: skeleton 1s ease infinite alternate;
        }

        .shadow-performance-graph {
            background-color: $shadowBgColor;
            max-width: 100%;
            height: pxtorem(215);
            animation: skeleton 1s ease infinite alternate;
        }
    }

    .shadow-filter-button {
        width: pxtorem(100);
        height: pxtorem(32);
        border-radius: $remof16px;
        margin-bottom: $remof30px;
        background-color: $shadowBgColor;
        animation: skeleton 1s ease infinite alternate;
    }

    .shadow-header {
        @include flexbox;
        @include flexboxAlignCenter;
        justify-content: space-between;
        margin-bottom: $remof15px;
    }

    .shadow-heading {
        width: pxtorem(115);
        height: pxtorem(35);
        background-color: $shadowBgColor;
        animation: skeleton 1s ease infinite alternate;
    }

    .shadow-settings {
        width: $remof28px;
        height: $remof28px;
        background-color: $shadowBgColor;
        animation: skeleton 1s ease infinite alternate;
        border-radius: $remof14px;
    }

    .shadow-header-info {
        margin-top: $remof36px;

        .shadow-info-title {
            width: pxtorem(150);
            height: $remof30px;
            background-color: $shadowBgColor;
            animation: skeleton 1s ease infinite alternate;
            margin-bottom: $remof16px;
        }

        .shadow-info-desc {
            width: 100%;
            height: $remof20px;
            background-color: $shadowBgColor;
            animation: skeleton 1s ease infinite alternate;
            margin-bottom: $remof10px;
        }
    }
}

.students-list-shadow {
    width: 100%;
    height: 100%;
    @include flexbox;
    flex-direction: column;

    .students-list-students-description {
        width: pxtorem(195);
        height: $remof20px;
        background-color: $shadowBgColor;
        animation: skeleton 1s ease infinite alternate;
        margin-bottom: $remof15px;
    }

    .students-list-chart {
        width: 100%;
        height: pxtorem(250);
        background-color: $shadowBgColor;
        animation: skeleton 1s ease infinite alternate;
    }

    .students-list-email-selected {
        width: pxtorem(115);
        height: $remof36px;
        background-color: $shadowBgColor;
        animation: skeleton 1s ease infinite alternate;
        margin-top: $remof5px;
        margin-bottom: $remof30px;
    }
}

.student-details-shadow {
    width: 100%;
    height: 100%;
    @include flexbox;
    flex-direction: column;

    .student-details-student-info {
        @include flexbox;
        @include flexboxAlignCenter;
        justify-content: space-between;
        margin-bottom: $remof15px;

        .student-info {
            @include flexbox;
            flex-direction: column;

            .student-details-name {
                width: pxtorem(250);
                height: $remof28px;
                background-color: $shadowBgColor;
                margin-bottom: pxtorem(6);
                animation: skeleton 1s ease infinite alternate;
            }

            .student-details-course {
                width: pxtorem(90);
                height: $remof18px;
                background-color: $shadowBgColor;
                margin-bottom: $remof5px;
                animation: skeleton 1s ease infinite alternate;
            }

            .student-details-tags {
                width: pxtorem(100);
                height: $remof25px;
                background-color: $shadowBgColor;
                animation: skeleton 1s ease infinite alternate;
            }
        }

        .student-details-email {
            width: pxtorem(180);
            height: $remof36px;
            border-radius: $remof16px;
            margin-bottom: $remof30px;
            background-color: $shadowBgColor;
            animation: skeleton 1s ease infinite alternate;
        }
    }
}
