@import "../../common/mixins.scss";

.notification {
    position: absolute;
    top: $remof10px;
    left: pxtorem(30);
    background: #fff;
    border: $remof1px solid #e9e9e9;
    border-left: pxtorem(3) solid #19a5a3;
    padding: $remof12px $remof20px $remof15px $remof10px;
    box-shadow: 0 $remof1px $remof1px $remof1px #f7f7f7;
    display: none;

    .notification-box {
        display: inline-block;

        &-icon {
            content: "";
            width: pxtorem(19);
            height: pxtorem(8);
            position: absolute;
            top: pxtorem(19);
            left: $remof20px;
            border: $remof2px solid #19a5a3;
            border-top: none;
            border-right: none;
            background: transparent;
            opacity: 1;
            -webkit-transform: rotate(-55deg);
            transform: rotate(-55deg);
        }
    }

    .notification-box-content {
        padding: 0 pxtorem(40) 0 pxtorem(38);

        span {
            font-weight: bold;
        }
    }

    .close {
        opacity: 0.6;
        font-size: pxtorem(27);
        font-weight: 400;
        position: absolute;
        right: pxtorem(8);
        top: $remof2px;
    }

    &.notification-error {
        border-left: pxtorem(3) solid #db0020;

        .notification-box-icon {
            color: #db0020;
            border: none;
            height: $remof18px;
            width: $remof18px;
            transform: none;
            top: $remof14px;
        }
    }

    &.message-open {
        display: block;
        z-index: 1;
    }
}
