@import "../../common/mixins.scss";

.knowledge-score-tooltip {
    .tooltip-inner {
        font-size: pxtorem(12);
        line-height: 0.7rem;
        max-width: pxtorem(260);
        font-weight: normal;
        color: #ffffff;
        padding: $remof10px $remof15px;
        text-align: left;
        border: none;
    }
}

.course-knowledge-text {
    font-size: $remof18px;
    font-weight: 400;
    color: #252525;
    line-height: $remof18px;
}

.course-knowledge-progress {
    padding: pxtorem(28) pxtorem(55) pxtorem(84) pxtorem(55);

    .progress {
        height: pxtorem(13);
        border-radius: 0;

        .bg-danger {
            background: $bgDanger !important;
        }

        .bg-warning {
            background: $bgWarning !important;
        }

        .bg-success {
            background: $bgSuccess !important;
        }
    }

    .class-average-line {
        width: 100%;
        margin-top: $remof14px;
        .line {
            width: $remof1px;
            height: $remof40px;
            border-left: 0.1rem solid #c7c7c7;
            position: relative;
            margin-top: -$remof40px;
        }
    }
}

.box-no-attempts {
    margin-left: 50%;
}

#courseKnowledgeTooltip {
    width: 100%;

    .line {
        transform: none;
        margin-left: 50%;
        width: $remof1px;
        height: pxtorem(29);
        border-left: 0.1rem solid #c7c7c7;
        position: relative;
    }

    .knowledge-score-box {
        transform: translate(-50%, 0);
        background-color: #fff;
        font-size: 0.875rem;
        font-weight: normal;
        line-height: 2rem;
        color: #ffffff;
        padding: 0.625rem 1.2375rem 0 1.2375rem;
        text-align: left;
        border: none;
        display: inline-block;

        .knowledge-score-label {
            background-color: $bgWarning;
            padding: $remof2px pxtorem(22);
            color: #252525;
            font-size: $remof18px;
            border-radius: $remof10px;
            text-align: center;
            font-weight: 600;
            height: pxtorem(37);
        }

        .avg-danger {
            color: #ffffff;
            background-color: $bgDanger;
        }

        .avg-warning {
            color: #252525;
            background-color: $bgWarning;
        }

        .avg-success {
            color: #ffffff;
            background-color: $bgSuccess;
        }

        .no-attempts {
            color: #252525;
            background-color: #c4c4c4;
            border-radius: $remof10px;
        }

        .hide-line {
            visibility: hidden;
        }
    }
}
